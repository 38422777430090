/**
 * @param number
 * @param property
 */

export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const checkPropertyUnit = (property) => {
  let format = /\W*_unit\W*/;

  if (property.match(format)) {
    return true;
  } else {
    return false;
  }
};
